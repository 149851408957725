import { LanguageSignal } from 'components/languageSelector/LanguageSelector'
import { ToastLevel, ToastSignal } from 'components'
import { piodydeSignal } from 'components/processView/ProcessView'
import MAIN_API from 'config/config'

export const loadPyodideScript = async () => {
  if (document.getElementById('pyodide-script')) return

  const script = document.createElement('script')
  script.id = 'pyodide-script'
  script.src = 'static/pyodide/pyodide.js'
  script.async = true

  script.onload = async () => {
    // todo remove any
    const pyodideInstance = await (window as any).loadPyodide()

    await pyodideInstance.loadPackage('micropip')

    const micropip = pyodideInstance.pyimport('micropip')
    const version = MAIN_API.package
    console.log(MAIN_API)
    await micropip.install(`static/pyodide/${version}`)

    pyodideInstance.runPython(`
        import sys
        import os
        vendoring_path = "/lib/python3.12/site-packages/irh_processing/vendoring" 
        sys.path.insert(0, vendoring_path)`)

    piodydeSignal.value = pyodideInstance
  }

  // todo refactor when upload history becomes available
  script.onerror = () => {
    ToastSignal.value = { message: LanguageSignal.value.terms.Error.anonymization.moduleNotLoaded,
      severity: ToastLevel.ERROR,
      timeout: 1000 }
  }

  document.body.appendChild(script)
}
